var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-search-box",
        { on: { enter: _vm.getList } },
        [
          _c("template", { slot: "search" }, [
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.opinionItems,
                    type: "search",
                    itemText: "follwUpExamStandardOpinionName",
                    itemValue: "follwUpExamStandardOpinion",
                    name: "follwUpExamStandardOpinion",
                    label: "소견",
                  },
                  model: {
                    value: _vm.searchParam.follwUpExamStandardOpinion,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.searchParam,
                        "follwUpExamStandardOpinion",
                        $$v
                      )
                    },
                    expression: "searchParam.follwUpExamStandardOpinion",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3" },
              [
                _c("c-select", {
                  attrs: {
                    comboItems: _vm.useFlagItems,
                    type: "search",
                    itemText: "codeName",
                    itemValue: "code",
                    name: "useFlag",
                    label: "사용여부",
                  },
                  model: {
                    value: _vm.searchParam.useFlag,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParam, "useFlag", $$v)
                    },
                    expression: "searchParam.useFlag",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "c-table",
        {
          ref: "table",
          attrs: {
            title: "추적검사관리 기준 목록",
            merge: _vm.grid.merge,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            editable: _vm.editable,
          },
          on: { "table-data-change": _vm.tableDataChange },
          scopedSlots: _vm._u([
            {
              key: "customArea",
              fn: function ({ props, col }) {
                return [
                  col.name === "follwUpExamStandardOpinion"
                    ? [
                        _c("c-text-column", {
                          attrs: {
                            editable: _vm.editable,
                            col: col,
                            props: props,
                          },
                          on: {
                            datachange: function ($event) {
                              return _vm.datachange(props, col)
                            },
                          },
                        }),
                        _vm.editable
                          ? _c(
                              "div",
                              [
                                _c(
                                  "q-btn-group",
                                  {
                                    staticClass: "ColumInnerBtnGroup",
                                    attrs: { outline: "" },
                                  },
                                  [
                                    _c(
                                      "q-btn",
                                      {
                                        staticClass: "ColumInnerBtn",
                                        attrs: {
                                          icon: "add",
                                          color: "red-6",
                                          "text-color": "white",
                                          align: "center",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.innerBtnClicked(
                                              col,
                                              props
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("q-tooltip", [
                                          _vm._v(" 소견별 분류 추가 "),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              },
            },
          ]),
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: { label: "추가", icon: "add" },
                        on: { btnClicked: _vm.add },
                      })
                    : _vm._e(),
                  _vm.editable
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.grid.data,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.save,
                          btnCallback: _vm.saveCallback,
                        },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    attrs: { label: "검색", icon: "search" },
                    on: { btnClicked: _vm.getList },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }